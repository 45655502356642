import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ShowIcon } from "../../assets/images/showicon.svg";
import { ReactComponent as HideIcon } from "../../assets/images/hideicon.svg";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../Utils/ThemeContext";
import { login, getProfile } from "../../api/authApi";
import { toast } from "react-toastify";

const Login = () => {
  // const { mode, toggleLight, toggleDark } = useContext(ThemeContext);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  // const handleThemeToggle = (event) => {
  //   if (event.target.checked) {
  //     toggleDark();
  //   } else {
  //     toggleLight();
  //   }
  // };

  const validateForm = () => {
    const formErrors = {};

    if (!email.trim()) {
      formErrors.email = "Email is required";
    }
    if (!password.trim()) {
      formErrors.password = "Password is required";
    }

    return formErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    try {
      if (Object.keys(formErrors).length !== 0) {
        return;
      }

      setIsLoggingIn(true); // Set logging in state to true

      const response = await login({
        email: email,
        password: password,
      });
      if (response.status === 200) {
        const userProfile = await getProfile();
        if (userProfile.is_verified) {
          navigate("/dashboard/home");
        } else {
          navigate("/unverified-account");
        }
      } else if (response.status === 400) {
        setError("Invalid Credential!");
        toast.error("Invalid Credential!");
      } else {
        setError(
          "Couldn't login, Unknown Error, please contact support@devnex.in.",
        );
      }
    } catch (err) {
      console.error("Login failed:", err);
      setError(
        "Couldn't login, Unknown Error, please contact support@devnex.in.",
      );
      toast.error(
        "Couldn't login, Unknown Error, please contact support@devnex.in.",
      );
    } finally {
      setIsLoggingIn(false);
    }
  };

  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    document.body.classList.add("center-body");

    return () => {
      document.body.classList.remove("center-body");
    };
  }, []);

  return (
    <Fragment>
      <div className="container-fluid" style={{ background: "#fff" }}>
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    {/* <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                      <div className="d-flex align-items-center me-2">
                        <span className="theme_heading">Mode:</span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox"
                          checked={mode === "dark-mode"}
                          onChange={handleThemeToggle}
                        />
                        <label htmlFor="checkbox" className="checkbox-label">
                          <i className="fas fa-moon"></i>
                          <i className="fas fa-sun"></i>
                          <span className="ball"></span>
                        </label>
                      </div>
                    </div> */}
                    <div className="d-flex text-center">
                      <div className="w-100">
                        <h3 className="loginpage-heading">Welcome back!</h3>
                        <p>Welcome back! Please enter your details.</p>
                      </div>
                    </div>
                    <form
                      className="signin-form"
                      onSubmit={handleLogin}
                      autoComplete="off"
                    >
                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="email" className="label pb-2">
                          Email
                        </label>
                        <input
                          className="form-control input_field form-controls-field"
                          type="email"
                          id="email"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          autoFocus
                          autoComplete="off"
                        />
                      </div>

                      <div className="form-group mb-2 mt-4">
                        <label htmlFor="password" className="pb-2">
                          Password
                        </label>
                        <div className="input-group">
                          <div className="input-group flex-nowrap">
                            <input
                              className="form-control input_field form-controls-field"
                              type={showLoginPassword ? "text" : "password"}
                              id="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="off"
                              required
                            />
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              onClick={toggleLoginPasswordVisibility}
                            >
                              {showLoginPassword ? <ShowIcon /> : <HideIcon />}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mb-3 mt-3">
                        <div className="flex items-center gap-2 ml-2">
                          <input
                            className=" "
                            type="checkbox"
                            id="flexCheckDefault"
                          />
                          <label
                            className="font-normal "
                            htmlFor="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="text-sm">
                          <Link to="/forgot" className="text-black">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="field p-0 mb-3 mt-2">
                        <button
                          className="btn-block w-100 login_button"
                          type="submit"
                          disabled={isLoggingIn}
                        >
                          {isLoggingIn ? "Signing in..." : "Sign in"}
                        </button>
                      </div>
                      <div className="text-center mt-6 text-sm">
                        <span>Don&apos;t have an account? </span>
                        <Link to="/signup" className=" text-purple-700  ">
                          Signup
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="Logo"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
