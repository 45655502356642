import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import profilePlaceholder from "../../assets/images/dummy2.jpg";
import editIcon from "../../assets/images/edit.svg";
import { getProfile } from "../../api/authApi";

const Profile = () => {
  const [profileImage, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted with phone number:", phoneNumber);
  };

  const fetchProfile = async () => {
    try {
      const userProfile = await getProfile();
      setProfileData(userProfile);
    } catch (error) {
      console.error("Failed to fetch profile: ", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []); // Add an empty dependency array to run once on mount

  return (
    <div className="container my-5">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/dashboard/home">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Profile
          </li>
        </ol>
      </nav>

      <div className=" py-12">
        <div className="flex flex-col">
          <h2 className=" mb-4">My Profile</h2>
          <div className="row justify-content-center">
            <div className="col-md-4 mb-4 mb-md-0 d-flex flex-column align-items-center">
              <img
                src={profileImage || profilePlaceholder}
                alt="Profile"
                className="img-fluid rounded-circle mb-3"
                style={{ width: "200px", height: "200px", objectFit: "cover" }}
              />
              <div className="mt-3 d-flex  align-items-center">
                <label
                  htmlFor="profile-image-upload"
                  className="btn d-flex btn-outline-primary me-2"
                >
                  <img src={editIcon} alt="Edit" className="me-2" />
                  Update Photo
                </label>
                <input
                  id="profile-image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="d-none"
                />
                <button className="btn btn-outline-danger">Remove</button>
              </div>
            </div>
            <div className="col-md-6">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    value={profileData.first_name + " " + profileData.last_name}
                    readOnly
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={profileData.email}
                    readOnly
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone_number"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="text-start">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm p-2 px-4 w-auto"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
