// src/api/authApi.js
import {
  axiosInstance,
  axiosInstanceUnAuthenticated,
  API_BASE_URL,
} from "./axiosConfig";
import { setCookie, deleteCookie, getCookie } from "./utils/cookies";

export const login = async (credentials, setProfile = true) => {
  try {
    const response = await axiosInstanceUnAuthenticated.post(
      `${API_BASE_URL}/usermgmt/knox/login/`,
      credentials,
    );
    setCookie("token", response.data.token);
    if (setProfile) {
      try {
        const response = await axiosInstance.get("/usermgmt/user_profile/");
        const profileData = response.data;
        setCookie("userProfile", JSON.stringify(profileData));
      } catch (e) {
        deleteCookie("userProfile");
        console.log("Failed to set userProfile data!");
      }
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return { status: 400, detail: error.response.data };
    }
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const register = async (userData) => {
  try {
    const response = await axiosInstanceUnAuthenticated.post(
      `${API_BASE_URL}/usermgmt/signup/`,
      userData,
    );
    return response.status == 200;
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("Registration Failed!");
  }
};

export const getProfile = async (cache_first = true) => {
  if (cache_first) {
    try {
      const profileData = JSON.parse(getCookie("userProfile"));
      return profileData;
    } catch (e) {
      console.log("Error getting profile data form cookie!");
      return await getProfile((cache_first = false));
    }
  }
  try {
    const response = await axiosInstance.get("/usermgmt/user_profile/");
    const profileData = response.data;
    setCookie("userProfile", JSON.stringify(profileData));
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const logOut = async () => {
  try {
    const response = await axiosInstance.post("/usermgmt/knox/logout/");
    deleteCookie("token");
    return response.data;
  } catch (error) {
    const error_msg = error.response
      ? error.response.data
      : { detail: "Network Error" };
    console.log(`Logout failed: ${JSON.stringify(error_msg)}`);
  } finally {
    deleteCookie("token");
  }
};

export const resendAccountVerificationEmail = async () => {
  try {
    const response = await axiosInstance.post(
      "/usermgmt/resend_email_verification/",
    );
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await axiosInstance.post("/usermgmt/verify_email/", {
      token: token,
    });
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
