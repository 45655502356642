import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Upload, Camera, Loader2 } from "lucide-react";
import { generateGarmentDetails } from "../../../api/modelsApi";
import GarmentDetailsDisplay from "./GarmentsDetailsDisplay";
import { updateTabState } from "../../../redux/studioSlice";

const GarmentAnalysis = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.DESC_GEN);

  const [file, setFile] = useState(state?.file || null);
  const [garmentDetails, setGarmentDetails] = useState(
    state?.garmentDetails || null,
  );
  const [error, setError] = useState(state?.error || null);
  const [isLoading, setIsLoading] = useState(state?.isLoading || false);
  const [previewUrl, setPreviewUrl] = useState(state?.previewUrl || null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    // Update Redux state when local state changes
    dispatch(
      updateTabState({
        tabId: "DESC_GEN",
        newState: {
          file,
          garmentDetails,
          error,
          isLoading,
          previewUrl,
        },
      }),
    );
  }, [file, garmentDetails, error, isLoading, previewUrl, dispatch]);

  const handleFileChange = useCallback((selectedFile) => {
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  }, []);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFileChange(e.dataTransfer.files[0]);
      }
    },
    [handleFileChange],
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please select an image file");
      return;
    }
    setIsLoading(true);
    setError(null);
    setGarmentDetails(null);
    try {
      const details = await generateGarmentDetails(file);
      setGarmentDetails(details);
    } catch (error) {
      setError("Failed to analyze garment. Please try again");
      console.error("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row bg-white min-h-screen p-6">
      <div className="w-full lg:w-1/2 pr-6">
        <div className="bg-white rounded-lg border border-purple-600 p-4 mb-4">
          <div
            className={`aspect-square w-full max-w-sm mx-auto mb-4 flex items-center justify-center rounded-lg overflow-hidden ${
              isDragging ? "border-2 border-dashed border-purple-600" : ""
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="preview"
                className="w-full h-full object-contain"
              />
            ) : (
              <div className="text-center">
                <Camera className="w-24 h-24 text-purple-700 mx-auto mb-2" />
                <p className="text-gray-500">Drag and drop an image here</p>
              </div>
            )}
          </div>
          <div className="w-full flex items-center justify-center mt-4">
            <label className="relative inline-block">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e.target.files[0])}
                className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
              />
              <span className="bg-white text-black border border-purple-600  py-2 px-4 rounded-md cursor-pointer hover:bg-purple-100 transition-colors flex items-center justify-center">
                <Upload className="mr-2" size={18} />
                <span className="text-sm">Upload Image</span>
              </span>
            </label>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          disabled={!file || isLoading}
          className={`w-full py-3 rounded-lg flex items-center justify-center text-lg font-semibold ${
            file && !isLoading
              ? "bg-purple-600 text-white hover:bg-purple-700"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          } transition-colors`}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Analyzing...
            </>
          ) : (
            "Analyze Garment"
          )}
        </button>
        {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
      </div>
      <div className="w-full lg:w-1/2 mt-6 lg:mt-0 lg:pl-6">
        {isLoading ? (
          <div className="bg-white rounded-lg border border-purple-200 p-6 shadow-sm flex items-center justify-center">
            <Loader2 className="mr-2 h-12 w-12 animate-spin text-purple-600" />
          </div>
        ) : garmentDetails ? (
          <GarmentDetailsDisplay garmentDetails={garmentDetails} />
        ) : (
          <div className="bg-white rounded-lg border border-gray-300 p-4 text-center">
            <p className="text-gray-500">
              Your garment analysis results will appear here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default GarmentAnalysis;
