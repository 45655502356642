// src/api/axiosConfig.js
import axios from "axios";
import { getCookie } from "./utils/cookies";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (API_BASE_URL === undefined) {
  throw new Error("API_BASE_URL not set!");
}
console.log(`REACT_APP_API_BASE_URL: ${API_BASE_URL}`);

// Create an instance of Axios
export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

export const axiosInstanceUnAuthenticated = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getCookie("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // config.headers["origin"] = window.location.origin;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstanceUnAuthenticated.interceptors.request.use(
  (config) => {
    // config.headers["origin"] = window.location.origin;
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // redirect to login page
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
