import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import Profile from "./Auth/Profile";
import Dashboard from "./Dashboard/Dashboard";
import Banner from "./Dashboard/Banner.jsx";
import Pagenotfound from "./Errors/Pagenotfound";
import Galary from "./ContentLibrary/GallaryPage.jsx";
import UploadImagePage from "./ContentLibrary/UploadImage.jsx";
import Forgot from "./Auth/Forgot";
import Studio from "./Dashboard/Studio.jsx";
import Faceswap from "./ImageProcessing/Faceswap";
import { UnVerifiedAccount } from "./Auth/UnVerifiedAccount";
import VerifyEmail from "./Auth/VerifyEmail";
import { PasswordReset } from "./Auth/PasswordReset";
import { UserProvider } from "./Auth/UserContext";
import { getCookie } from "../api/utils/cookies";

const ProtectedRoute = ({ children }) => {
  const token = getCookie("token");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const RootRedirect = () => {
  const token = getCookie("token");
  if (token) {
    return <Navigate to="/dashboard/home" replace />;
  }
  return <Login />;
};

const Routers = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootRedirect />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/unverified-account" element={<UnVerifiedAccount />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<PasswordReset />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<Navigate to="app" replace />} />
            <Route path="home" element={<Banner />} />
            <Route path="app" element={<Studio />} />
            {/* <Route path="poseswap" element={<PoseSwap />} /> */}
            <Route path="faceswap" element={<Faceswap />} />
            <Route path="profile" element={<Profile />} />
            <Route path="Galary" element={<Galary />} />
            <Route path="galary/UploadImage" element={<UploadImagePage />} />
          </Route>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
};

export default Routers;
