import { React, useState } from "react";
import { Images, Upload, UserSearchIcon } from "lucide-react";
import { DownloadButton } from "../Utils/DownloadButton.jsx";
import { Camera } from "lucide-react";

const ImageProcessor = ({
  title,
  images,
  uploadTitle,
  onImageSelect,
  onImageUpload,
  onRun,
  selectedImage,
  selectedFilter,
  appliedFilter,
  isRunning,
  isDownloadReady,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [UploadedImage, setUploadedImage] = useState({});
  const handleFilterSelect = (filter) => {
    onImageSelect(filter);

    setUploadedImage({
      newImage: filter,
      newImageUrl: filter,
    });
    setShowAllImages(false);
  };

  const handleShowMore = () => {
    setShowAllImages(true);
  };
  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setUploadedImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onImageSelect(newImageUrl);
    }
  };
  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="flex flex-col lg:flex-row bg-white min-h-screen">
      {/* Left side - Filter Images */}
      <div className="w-full lg:w-1/4 p-6 rounded-xl h-max border border-[#ee0d0d] mt-4">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>

        <div className="aspect-square w-fit mx-auto mb-4 ">
          {UploadedImage.newImageUrl ? (
            <img
              src={UploadedImage.newImageUrl}
              alt="preview"
              className="w-[200px] h-full object-contain rounded-lg"
            />
          ) : (
            <Camera className="w-24 h-24 text-purple-700 mx-auto mb-2" />
          )}
        </div>

        <div className="w-full flex items-center justify-center mt-4">
          <label className="relative inline-block">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e)}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            <span
              className="bg-white text-black border py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center"
              style={{ borderColor: "purple" }}
            >
              <Upload className="mr-4" />
              <span className="text-sm">Upload Image</span>
            </span>
          </label>
        </div>

        <div className="flex w-full justify-center items-center ">
          <div className="border w-full"></div>
          <p className="mt-3 mx-4">OR</p>
          <div className="border w-full"></div>
        </div>

        <div className="flex mb-4 items-center overflow-hidden">
          <div className=" flex w-24 items-center gap-4">
            {images.slice(0, 3).map((img, index) => (
              <img
                key={index}
                src={img}
                className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                  selectedFilter === img
                    ? "ring-4 ring-purple-600"
                    : "ring-1 ring-gray-300 hover:ring-purple-400"
                }`}
                alt={`Filter ${index + 1}`}
                onClick={() => handleFilterSelect(img)}
              />
            ))}
          </div>
        </div>

        {/* Browse More Button */}
        <button
          className=" w-full py-2 bg-purple-600 text-white text-sm rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
          onClick={handleShowMore}
        >
          <UserSearchIcon className="mr-4" />
          <span className="text-sm">View All Image</span>
        </button>
      </div>
      <div className="w-full lg:w-3/4 xl:pl-5 lg:pt-6 ">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-[45%] h-fit bg-white rounded-lg border border-[#ee0d0d] p-4">
            <h2 className="text-2xl font-bold mb-4">Upload a {uploadTitle}</h2>
            <div className="aspect-square w-fit mx-auto mb-4">
              {selectedImage.blobImageUrl ? (
                <img
                  src={selectedImage.blobImageUrl}
                  alt="preview"
                  className={`w-[300px] h-full object-contain rounded-lg ${
                    selectedFilter === selectedImage.newImageUrl
                      ? "ring-4 ring-purple-600"
                      : ""
                  }`}
                />
              ) : (
                <Images className="w-[200px] h-[300px] text-purple-700" />
              )}
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              <label className="relative inline-block">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onImageUpload}
                  className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                />
                <span
                  className="bg-white text-black border py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center"
                  style={{ borderColor: "purple" }}
                >
                  <Upload className="mr-4" />
                  <span className="text-sm">Upload Image</span>
                </span>
              </label>
            </div>
          </div>

          {/* Processed Image Section */}
          <div className="w-full md:w-[45%] bg-white rounded-lg border p-4">
            <h2 className="text-2xl font-bold mb-4">Output</h2>
            <div className="aspect-square w-fit mx-auto mb-4">
              {isRunning ? (
                <div className="w-[300px] h-[300px] bg-gray-200 rounded-lg flex items-center justify-center">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              ) : appliedFilter ? (
                <img
                  src={appliedFilter}
                  alt="filtered"
                  className="w-[300px] h-full object-contain rounded-lg cursor-pointer"
                  onClick={() => openFullscreenImage(appliedFilter)}
                />
              ) : (
                <div className="w-[300px] h-[300px] bg-gray-200 rounded-lg flex items-center justify-center">
                  <p className="text-gray-500">
                    Your creations will come here.
                  </p>
                </div>
              )}
            </div>
            {isDownloadReady && <DownloadButton imageUrl={appliedFilter} />}
          </div>
        </div>

        {/* Run Button */}
        <div className="mt-3">
          <button
            className={`w-fit px-6 py-3 rounded-lg flex items-center justify-center text-lg font-semibold ${
              selectedFilter && selectedImage.blobImage
                ? "bg-purple-600 text-white hover:bg-purple-700"
                : "bg-gray-300 text-gray-600 cursor-not-allowed"
            } transition-colors`}
            onClick={onRun}
            disabled={!selectedFilter || !selectedImage.blobImage || isRunning}
          >
            {isRunning ? "Processing..." : "Run"}
          </button>
        </div>
      </div>

      {/* Fullscreen Image Modal */}
      {fullscreenImage && (
        <div
          onClick={closeFullscreenImage}
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
        >
          <div className="relative w-full h-full max-w-4xl max-h-4xl">
            <img
              src={fullscreenImage}
              alt="fullscreen"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}

      {/* Pop-up for all images */}
      {showAllImages && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
            <button
              className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
              onClick={() => setShowAllImages(false)}
            >
              ×
            </button>
            <h3 className="text-2xl font-bold mb-6">Choose an Image</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                    selectedFilter === img
                      ? "ring-4 ring-purple-600"
                      : "ring-1 ring-gray-300 hover:ring-purple-400"
                  }`}
                  alt={`Filter ${index + 1}`}
                  onClick={() => handleFilterSelect(img)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageProcessor;
