import React, { useContext } from "react";
import { ThemeContext } from "../Utils/ThemeContext";
import { UserContext } from "../Auth/UserContext"; // Adjust the path as needed
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import userIcon from "../../assets/images/user.svg";
import GalaryImg from "../../assets/images/gallery.svg";
import logout from "../../assets/images/logout.svg";
import logo from "../../assets/images/logo.svg";
import menuIcon from "../../assets/images/menu.svg";
import theme from "../../assets/images/theme.svg";
import { logOut, getProfile } from "../../api/authApi";
import { User } from "lucide-react";
import { useState, useEffect } from "react";

const Header = () => {
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);

  const [profileData, setProfileData] = useState({});

  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
    } else {
      toggleLight();
    }
  };

  const fetchProfile = async () => {
    try {
      const userProfile = await getProfile();
      setProfileData(userProfile);
    } catch (error) {
      console.error("Failed to fetch profile: ", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const navigate = useNavigate(); // Initialize navigate

  // Updated logout function
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <nav className="navbar navbar-expand-md  navbar-light fixed-top nav-active">
      <div className="container-fluid  navbar-page">
        <Link to="/dashboard/home" className="navbar-brand">
          <img
            src={logo}
            alt="logo"
            style={{ height: "auto", width: "35%", objectFit: "contain" }}
            className="m-0"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <img src={menuIcon} className="w-100" alt="menu" />
          </span>
        </button>
        <div
          className="collapse navbar-collapse header_menu"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link profile_navlink p-0"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {profileData?.photo ? (
                  <img
                    src={profileData.photo}
                    alt="User Avatar"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <User className="w-8 h-8 text-gray-400" />
                )}
              </a>
              <ul className=" dropdown-menu  dropdown-menu-end overflow-x-hidden w-64">
                <div className=" d-flex justify-content-start align-items-center">
                  {profileData?.photo ? (
                    <img
                      src={profileData.photo}
                      alt="User Avatar"
                      className="w-8 h-8 rounded-full"
                    />
                  ) : (
                    <User className="w-8 h-8 ml-4 mr-2 text-gray-400" />
                  )}
                  <div className="media-body me-3 mt-2">
                    <h5 className="mt-0 m-auto profile_heading">
                      {profileData?.first_name || "Guest"}
                    </h5>
                    <h6 className="email-text">
                      {profileData?.email || "No Email"}
                    </h6>
                  </div>
                </div>
                <hr className="mt-2 mb-1" />
                <li>
                  <Link
                    className="flex text-black gap-3 pt-2 pl-3.5"
                    to="/dashboard/profile"
                  >
                    <img src={userIcon} alt="My Profile" className="w-5" />
                    My Profile
                  </Link>
                </li>
                <li className="d-flex justify-content-between pl-3 pb-2 align-items-center">
                  <div className="flex text-black gap-3  pt-2">
                    <img src={theme} alt="no_img" className="w-6" />
                    <span className="">Theme</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked={mode === "dark-mode"}
                      onChange={handleThemeToggle}
                    />
                    <label htmlFor="checkbox" className="checkbox-label mr-3">
                      <i className="fas fa-moon"></i>
                      <i className="fas fa-sun"></i>
                      <span className="ball"></span>
                    </label>
                  </div>
                </li>

                <li>
                  <Link
                    className="flex text-black gap-3 pl-4  pb-2"
                    to="/dashboard/galary"
                  >
                    <img src={GalaryImg} alt="Gallary" />
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex text-black gap-3 pl-4"
                    to="/"
                    style={{ color: "#FA615C" }}
                    onClick={handleLogout} // Call logout handler
                  >
                    <img src={logout} alt="Logout" />
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
